import React from "react"

import Container from "components/container"
import { EmberCard, EmberCardSection } from "components/generic/ember-card"
import Hero from "components/hero"
import { DriveLayout } from "components/layout-custom"

import lights1 from "./images/lights-1.png"
import lights2 from "./images/lights-2.png"
import lights3 from "./images/lights-3.png"
import lights4 from "./images/lights-4.png"
import lights5 from "./images/lights-5.png"
import lights6 from "./images/lights-6.png"
import lights7 from "./images/lights-7.png"
import lights8 from "./images/lights-8.png"

const Page = () => (
  <DriveLayout title="Yutong Dashboard Lights">
    <Hero title="Yutong Dashboard Lights" />
    <Container size="medium">
      <EmberCard>
        <EmberCardSection title="Page 1">
          <img alt="Dashboard Lights - Page 1" src={lights1} />
        </EmberCardSection>
        <EmberCardSection title="Page 2">
          <img alt="Dashboard Lights - Page 2" src={lights2} />
        </EmberCardSection>
        <EmberCardSection title="Page 3">
          <img alt="Dashboard Lights - Page 3" src={lights3} />
        </EmberCardSection>
        <EmberCardSection title="Page 4">
          <img alt="Dashboard Lights - Page 4" src={lights4} />
        </EmberCardSection>
        <EmberCardSection title="Page 5">
          <img alt="Dashboard Lights - Page 5" src={lights5} />
        </EmberCardSection>
        <EmberCardSection title="Page 6">
          <img alt="Dashboard Lights - Page 6" src={lights6} />
        </EmberCardSection>
        <EmberCardSection title="Page 7">
          <img alt="Dashboard Lights - Page 7" src={lights7} />
        </EmberCardSection>
        <EmberCardSection title="Page 8">
          <img alt="Dashboard Lights - Page 8" src={lights8} />
        </EmberCardSection>
      </EmberCard>
    </Container>
  </DriveLayout>
)

export default Page
